import { useOverlayContext } from 'seagull';
import { useStoreActions } from "../store/store-context";
export function useAnchorAdmin() {
  const {
    api
  } = useStoreActions();
  const {
    showErrorToast
  } = useOverlayContext();
  return businessId => {
    api.loginAsAnchorAdmin(businessId).then(() => {
      window.open("https://".concat(window._authDomain, "/home"));
    }, e => {
      var _e$message;
      showErrorToast((_e$message = e === null || e === void 0 ? void 0 : e.message) !== null && _e$message !== void 0 ? _e$message : 'Failed to login as Anchor admin, try again later');
    });
  };
}