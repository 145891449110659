// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes GGOV3{0%{opacity:0;transform:translateY(32px)}100%{opacity:1;transform:translateY(0)}}@media not print{.d_Y5L{height:100%;overflow:auto}.d_Y5L.nMNTj{filter:blur(3px)}}.SwiI8{--icon-color: var(--white);position:fixed;top:0;left:0;width:100%;height:100%;display:flex;align-items:center;justify-content:center;z-index:1400}.SwiI8>.WdhPp{width:inherit;height:inherit;background-color:#000;opacity:.3;position:absolute}.m9eZP{-webkit-tap-highlight-color:transparent}.T39o9{background-color:rgba(0,0,0,.5)}.nt3G2{display:flex;align-items:center;justify-content:center;height:100%}.nt3G2>:last-child{z-index:1}.m9eZP{position:absolute;top:0;height:100%;left:0;width:100%}.i6N8h{position:fixed;top:48px;left:50%;transform:translateX(-50%);width:min-content;height:min-content;z-index:1350}.i6N8h>.U8gSj{margin-bottom:8px;animation:MJgEd linear forwards;animation-duration:var(--toastDuration, 4.9s)}.i6N8h>.U8gSj:last-of-type{margin-bottom:0}@keyframes MJgEd{0%{opacity:0;transform:translateY(16px);max-height:48px}5%{opacity:1;transform:translateY(0px);max-height:48px}95%{opacity:1;transform:translateY(0px);max-height:48px}100%{opacity:0;transform:translateY(-16px);max-height:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlayContext": `d_Y5L`,
	"blur": `nMNTj`,
	"globalLoader": `SwiI8`,
	"overlay": `WdhPp`,
	"modalBackdrop": `m9eZP`,
	"modalBg": `T39o9`,
	"modalContainer": `nt3G2`,
	"toastsContainer": `i6N8h`,
	"toast": `U8gSj`,
	"toast-animation": `MJgEd`,
	"modalanimation": `GGOV3`
};
export default ___CSS_LOADER_EXPORT___;
